import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import Style from "./about.module.scss"

/**
 * AboutEnsemble: Includes information about Wet Ink Ensemble
 */
const AboutEnsemble = props => {
  return (
    <div className={Style.aboutEnsemblePane}>
      <h2>ABOUT WET INK</h2>
      <hr />
      {documentToReactComponents(
        props.aboutData.aboutWetInk.json,
        about_options()
      )}
    </div>
  )
}

export default AboutEnsemble

/* =================================================== */

/**
 * about_options: Default rich text parsing options
 * for the about page
 */
const about_options = () => {
  const option_node = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <img
            title={node.data.target.fields.title}
            className={Style.ensemblePhoto}
            src={`http:${node.data.target.fields.file["en-US"].url}`}
            alt={node.data.target.fields.title}
          />
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p>{children}</p>
      },
    },
  }
  return option_node
}
