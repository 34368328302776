import React, { useState } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import Style from "./about.module.scss"

/**
 * AboutMembers: Includes the bios for each Wet Ink member
 */
const AboutMembers = ({ memberData }) => {
  let memberBios = [
    { id: 0, name: "Alex Mincek", bio: memberData.mincekBio.json },
    { id: 1, name: "Eric Wubbels", bio: memberData.wubbelsBio.json },
    { id: 2, name: "Erin Lesser", bio: memberData.lesserBio.json },
    { id: 3, name: "Ian Antonio", bio: memberData.antonioBio.json },
    { id: 4, name: "Josh Modney", bio: memberData.modneyBio.json },
    { id: 5, name: "Kate Soper", bio: memberData.soperBio.json },
    { id: 6, name: "Mariel Roberts", bio: memberData.robertsBio.json },
    { id: 7, name: "Sam Pluta", bio: memberData.plutaBio.json },
  ]

  const [currentBio, setCurrentBio] = useState(memberBios[0].bio)

  const MembersHeader = memberBios.map(memberBio => {
    return (
      <div style={{ display: "inline" }} key={memberBio.id}>
        <b
          className={Style.memberLink}
          // Supresses dumb warning
          role="button"
          tabIndex={0}
          onKeyDown={() => setCurrentBio(memberBio.bio)}
          onClick={() => setCurrentBio(memberBio.bio)}
          // Suppress dumb outline
          style={{ outline: "none" }}
        >
          {memberBio.name}
        </b>
      </div>
    )
  })

  return (
    <div className={Style.aboutMembersPane}>
      <h2>ARTISTS/CO-DIRECTORS</h2>
      <hr />
      <div className={Style.memberHeader}>{MembersHeader}</div>
      <hr />
      {documentToReactComponents(currentBio, members_options())}
    </div>
  )
}

export default AboutMembers

/* =================================================== */

/**
 * members_options: Default rich text parsing options
 * for the about page
 */
const members_options = () => {
  const option_node = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <img
            title={node.data.target.fields.title}
            className={Style.memberPhoto}
            src={`http:${node.data.target.fields.file["en-US"].url}`}
            alt={node.data.target.fields.title}
          />
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p>{children}</p>
      },
    },
  }
  return option_node
}
