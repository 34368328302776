import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import AboutEnsemble from "../components/about/about-ensemble.js"
import AboutMembers from "../components/about/about-members.js"
import AboutInsta from "../components/about/about-insta.js"

const About = () => {
    // Array of all the data for every Wet Ink event
    const ql_about = useStaticQuery(graphql`
        query {
            contentfulAboutInfo {
                aboutWetInk {
                    json
                }
                mincekBio {
                    json
                }
                wubbelsBio {
                    json
                }
                lesserBio {
                    json
                }
                modneyBio {
                    json
                }
                antonioBio {
                    json
                }
                soperBio {
                    json
                }
                robertsBio {
                    json
                }
                plutaBio {
                    json
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO title="About" />
            <AboutEnsemble aboutData={ql_about.contentfulAboutInfo} />
            <AboutMembers memberData={ql_about.contentfulAboutInfo} />
            <AboutInsta />
        </Layout>
    )
}

export default About
