import React from "react"
import { InstagramEmbed } from "react-social-media-embed"
import Style from "./about.module.scss"

/**
 * AboutTwitter: Twitter feed on bottom left of the about page
 */
const AboutInsta = props => {
    return (
        <div className={Style.aboutInstaPane}>
            <InstagramEmbed url="https://www.instagram.com/wetink.ensemble/" />
        </div>
    )
}

export default AboutInsta
